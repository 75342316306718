import React, { useState, useEffect, useRef } from 'react';
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import { preloadRemotePages, loadCachedPage, hasPageCache, pagesToCache } from './cacheWP';

import Loadable from 'react-loadable';
import Loading from './components/Loading'

import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import Sidebar from './components/Sidebar';
import Nav from './components/Nav';
import Modal from './components/Modal';
import Footer from './components/Footer';

import AnalyticsTracker from 'components/AnalyticsTracker';

// Adding Cookies to the page
import CookieConsent from 'react-cookie-consent';

import store from './store';

// These are for mapping stuff to props
import { toggleSidebar } from './actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PWAInstalPrompt from './components/shared/PWAInstallPrompt';

import { Helmet } from "react-helmet"
import config from "config"
const { title, description } = config.meta.defaults


// Loadable routes
const loadableConfig = {
  loading: Loading,
  timeout: 10000
}

const LoadableMenu = Loadable({
  loader: () => import('./components/Menu'),
  ...loadableConfig
});

const LoadableTargetPlantDensity = Loadable({
  loader: () => import('./components/TargetPlantDensity'),
  ...loadableConfig
});

const LoadableHarvestLoss = Loadable({
  loader: () => import('./components/HarvestLoss/HarvestLoss'),
  ...loadableConfig
});

const LoadableCanolaCounts = Loadable({
  loader: () => import('./components/CanolaCounts/CanolaCounts'),
  ...loadableConfig
});

const LoadableBlacklegLoss = Loadable({
  loader: () => import('./components/BlacklegLoss/BlacklegLoss'),
  ...loadableConfig
});

const LoadableSeedingRateParent = Loadable({
  loader: () => import('./components/SeedingRateParent'),
  ...loadableConfig
});

const LoadableMaximumYieldDiagnostic = Loadable({
  loader: () => import('./components/MaximumYieldDiagnostic'),
  ...loadableConfig
});

const RenderCache = ({ pageKey, url }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [checkCount, setCheckCount] = useState(0);
  
  useEffect(() => {
    async function checkAndLoadCache() {
      console.log(`Checking cache for ${pageKey} (attempt ${checkCount + 1})`);
      setIsLoading(true);
      
      // Check if page is cached
      const isCached = await hasPageCache(pageKey);
      
      if (isCached) {
        console.log(`Breaking out of React app for ${pageKey}`);
        loadCachedPage(pageKey);
        return;
      } 
      
      console.log(`No cache available for ${pageKey}, preloading...`);
      // Start preloading remote pages
      await preloadRemotePages();
      
      // After preloading, check cache again
      const isNowCached = await hasPageCache(pageKey);
      if (isNowCached) {
        console.log(`Cache now available for ${pageKey} after preloading, loading from cache`);
        loadCachedPage(pageKey);
        return;
      }
      
      // If we've made multiple attempts and still no cache, stop trying
      if (checkCount >= 2) {
        console.log(`Failed to load ${pageKey} after ${checkCount + 1} attempts`);
        setIsLoading(false);
        return;
      }
      
      // Increment check count for next attempt
      setCheckCount(prevCount => prevCount + 1);
    }
    
    checkAndLoadCache();
  }, [pageKey, url, checkCount]); // Add checkCount as dependency so we retry when it changes
  
  return <Loading text={`Loading ${pageKey} content...`} />;
};

const App = (props) => {
  // Renders on each component change, triggering an event
  // https://stackoverflow.com/a/63249329
  AnalyticsTracker();

  const location = useLocation();

  let pathname;
  if (!isNil(location.pathname)) {
    pathname = location.pathname
  } else {
    console.log('location.pathname is null')
    pathname = window.location.pathname
  }
  const urlSegments = pathname.split('/');

  const lastRoute = urlSegments[urlSegments.length - 1]
  const lastRouteCamel = camelCase(lastRoute)
  const pageTitle = get(config.meta, lastRouteCamel + '.title', config.meta.defaults.title);
  const pathClassName = isNil(lastRoute) || isEmpty(lastRoute) ? 'home' : lastRoute;

  // Preload remote Wordpress homepage in the background once
  useEffect(() => {
    preloadRemotePages();
  }, []);

  // Check if we should replace the entire document with cached content
  useEffect(() => {
    async function checkForDocumentReplacement() {
      // Only check for homepage paths
      if (pathname === '/calculator/' || pathname === '/' || pathname === '/calculator') {
        const pageKey = 'home';
        const isCached = await hasPageCache(pageKey);
        
        if (isCached) {
          console.log("Replacing document with cached homepage content");
          loadCachedPage(pageKey);
          return; // Early return since document is being replaced
        } else {
          console.log("Homepage not cached yet, preloading...");
          await preloadRemotePages();
          
          // Check again after preloading
          const isNowCached = await hasPageCache(pageKey);
          if (isNowCached) {
            console.log("Homepage now cached after preloading");
            loadCachedPage(pageKey);
            return;
          }
        }
      }
    }
    
    checkForDocumentReplacement();
  }, [pathname]);

  let classNamesArr = [];
  if (props.sidebar) {
    classNamesArr.push('sidebar-open');
  }
  if (props.modal) {
    classNamesArr.push('modal-open');
  }
  let classNamesStr = classNamesArr.join(' ');

  // Trigger actions on location (route) change.
  useEffect(() => {

    const params = new URLSearchParams(location.search)

    // Loading state from email when the query string exists.
    if (params.get('v')) {
      store.dispatch({
        type: 'LOAD_FROM_QUERY_STRING',
        location: location,
        params: params
      })
    }

    // When query string has 'metric' update state;
    // This is not used in tools that have specific metric toggle, eg: harvest loss.
    if (params.get('metric')) {
      store.dispatch({
        type: 'TOGGLE_USE_METRIC',
        metric: parseInt(params.get('metric'))
      })
    }

    // Reset emailing and saving (may no longer be used) states
    store.dispatch({ type: 'RESET_EMAILING_STATE' })

    // Close all accordions on route change
    store.dispatch({ type: 'CLOSE_ACCORDIONS' })

    // Close result pane on route change
    store.dispatch({ type: 'HIDE_RESULT' })

    // Close sidebar menu on route change
    store.dispatch({ type: 'HIDE_SIDEBAR' })
  }, [location]);

  return (
    <div id="page" className={classNamesStr}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Sidebar />
      <main id="main" className={pathClassName}>
        <Nav title={pageTitle.replace(/\s\|\s.*/, '')} />

        <section id="content">
          {/* Use Switch to only render the first Route or Redirect that matches the location */}
          <Switch>
            <Route exact path={process.env.PUBLIC_URL + '/'} component={LoadableMenu} />
            <Route path={process.env.PUBLIC_URL + '/target-plant-density'} component={LoadableTargetPlantDensity} />
            <Route path={process.env.PUBLIC_URL + '/seeding-rate'} component={LoadableSeedingRateParent} />
            <Route path={process.env.PUBLIC_URL + '/canola-counts'} component={LoadableCanolaCounts} />
            <Route path={process.env.PUBLIC_URL + '/harvest-loss'} component={LoadableHarvestLoss} />
            <Route path={process.env.PUBLIC_URL + '/blackleg-loss'} component={LoadableBlacklegLoss} />
            <Route path={process.env.PUBLIC_URL + '/combine-optimization'} component={LoadableMaximumYieldDiagnostic} />
            {/* WP page routes */}
            <Route path={process.env.PUBLIC_URL + '/home'} render={() => <RenderCache pageKey="home" url="/" />} />
            <Route path={process.env.PUBLIC_URL + '/sclerotinia-resources'} render={() => <RenderCache pageKey="resources" url="/calculator/sclerotinia-resources" />} />
            <Route path={process.env.PUBLIC_URL + '/sclerotinia-rating-method'} render={() => <RenderCache pageKey="ratingMethod" url="/calculator/sclerotinia-rating-method" />} />
            <Route path={process.env.PUBLIC_URL + '/sclerotinia-photo-gallery'} render={() => <RenderCache pageKey="gallery" url="/calculator/sclerotinia-photo-gallery" />} />
            <Route path={process.env.PUBLIC_URL + '/make-a-sclerotinia-depot'} render={() => <RenderCache pageKey="sclerotiaDepot" url="/calculator/make-a-sclerotinia-depot" />} />
          </Switch>
        </section>

        <div
          className="active-sidebar-overlay"
          onClick={() => {
            props.actions.toggleSidebar();
          }}
        />
      </main>
      <Footer location={props.location} />

      <Modal />

      <CookieConsent
        buttonText="Dismiss"
        expire="30"
        disableStyles={true}
        containerClasses="cookie-bar"
        contentClasses="cookie-content"
        buttonClasses="cookie-dismiss"
      >
        <p>Privacy & Cookies: This site uses cookies. By continuing to use this website, you agree to their use. To find out more, including how to control cookies, see here: <a href="/privacy-policy/" target="_blank">Privacy policy</a></p>
      </CookieConsent>

      <PWAInstalPrompt />
    </div>
  );
}

const stateToProps = (state) => {
  return {
    sidebar: state.sidebar,
    modal: state.modal.open
  };
}

const dispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ toggleSidebar }, dispatch) };
}

export default connect(stateToProps, dispatchToProps)(App);
