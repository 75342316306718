import { cacheNames, clientsClaim, setCacheNameDetails } from 'workbox-core';

const pagesToCache = [
    {
        key: 'home',
        remoteUrl: 'https://www.canolacouncil.org/home-calculator/',
        url: '/calculator/'
    },
    {
        key: 'resources',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-resources-calculator/',
        url: '/calculator/sclerotinia-resources'
    },
    {
        key: 'ratingMethod',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-rating-method-calculator/',
        url: '/calculator/sclerotinia-rating-method'
    },
    {
        key: 'gallery',
        remoteUrl: 'https://www.canolacouncil.org/sclerotinia-photo-gallery-calculator/',
        url: '/calculator/sclerotinia-photo-gallery'
    },
    {
        key: 'sclerotiaDepot',
        remoteUrl: 'https://www.canolacouncil.org/make-a-sclerotinia-depot-calculator/',
        url: '/calculator/make-a-sclerotinia-depot'
    }
];

/**
 * Fetches and caches a set of pages from WordPress.
 */
async function preloadRemotePages() {
    const cache = await caches.open(cacheNames.precache);

    await Promise.all(
        pagesToCache.map(async (page) => {
            const cachedHtml = await cache.match(page.key);

            // Skip fetching if offline and page is already cached
            if (cachedHtml && !navigator.onLine) {
                console.log(`Page ${page.key} already cached`);
                return;
            }

            try {
                const response = await fetch(page.remoteUrl);

                if (!response.ok) {
                    console.error(`Failed to fetch ${page.remoteUrl}`);
                    return;
                }

                const text = await response.text();
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, 'text/html');

                // Inject script to prevent lazy loading
                const scriptContent = `
                    document.querySelectorAll('img[loading="lazy"]').forEach(img => {
                        img.loading = 'eager';
                    });
                `;
                const scriptTag = document.createElement("script");
                scriptTag.textContent = scriptContent;
                doc.head.appendChild(scriptTag);

                const newHtml = doc.documentElement.outerHTML;
                await cache.put(page.key, new Response(newHtml, { headers: { 'Content-Type': 'text/html' } }));

                console.log(`Cached HTML for ${page.key}`);

                // Cache additional assets
                await cacheLinkedAssets(doc, cache);

            } catch (error) {
                console.error(`Error fetching ${page.remoteUrl}:`, error);
            }
        })
    );
}

/**
 * Caches linked assets (CSS, JS, images) from the fetched page.
 */
async function cacheLinkedAssets(doc, cache) {
    const assetUrls = new Set();

    // Collect all asset URLs from the HTML document
    doc.querySelectorAll("link[rel='stylesheet']").forEach(link => assetUrls.add(link.href));
    doc.querySelectorAll("script[src]").forEach(script => assetUrls.add(script.src));
    doc.querySelectorAll("img[src]").forEach(img => assetUrls.add(img.src));

    await Promise.all([...assetUrls].map(async (url) => {
        if (!url) return;

        // Check if asset is already in cache
        const cachedResponse = await cache.match(url);
        if (cachedResponse) {
            return; // Skip fetching
        }

        try {
            const response = await fetch(url);
            if (response.ok) {
                await cache.put(url, response);
            }
        } catch (error) {
            console.error(`Failed to cache asset: ${url}`, error);
        }
    }));
}

/**
 * Loads the cached version of a page by its key.
 * @param {string} pageKey - The key of the page to load.
 * @returns {Promise<boolean>} - Whether content was loaded
 */
async function loadCachedPage(pageKey) {
    console.log("loadCachedPage", pageKey);
    
    // Check if content was already replaced
    if (window.__contentReplaced) {
        console.log("Content already replaced, skipping");
        return false;
    }
    
    // Show loading animation immediately
    showLoadingAnimation();
    
    const cache = await caches.open(cacheNames.precache);
    let cachedResponse = await cache.match(pageKey);

    // If no cache found, try to preload and check again
    if (!cachedResponse) {
        console.log(`No cache found for page key: ${pageKey}. Preloading...`);
        await preloadRemotePages();
        
        // Check again after preloading
        cachedResponse = await cache.match(pageKey);
        
        // Still no cache? Return false
        if (!cachedResponse) {
            console.log(`Still no cache available for ${pageKey} after preloading.`);
            removeLoadingAnimation();
            return false;
        }
        
        console.log(`Cache now available for ${pageKey} after preloading.`);
    }

    // We have cached content, load it
    const cachedHtml = await cachedResponse.text();
    console.log(`Loading cached content for ${pageKey}`);
    
    // Mark content as replaced to prevent duplicate calls
    window.__contentReplaced = true;

    // Replace document with cached HTML
    document.open();
    document.write(cachedHtml);
    document.close();
    
    return true;
}

/**
 * Shows a loading animation in the current document
 */
function showLoadingAnimation() {
    // Check if loading animation already exists
    if (document.getElementById('cache-loading-animation')) return;
    
    const loadingDiv = document.createElement('div');
    loadingDiv.id = 'cache-loading-animation';
    loadingDiv.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        z-index: 9999;
    `;
    
    // Create spinner element
    const spinner = document.createElement('div');
    spinner.style.cssText = `
        width: 50px;
        height: 50px;
        border: 5px solid rgba(0, 0, 0, 0.1);
        border-top-color: #244c5a;
        border-radius: 50%;
        animation: spin 1s ease-in-out infinite;
    `;
    
    // Add animation keyframes
    const style = document.createElement('style');
    style.textContent = `
        @keyframes spin {
            to { transform: rotate(360deg); }
        }
    `;
    
    document.head.appendChild(style);
    loadingDiv.appendChild(spinner);
    document.body.appendChild(loadingDiv);
}

/**
 * Removes the loading animation from the document
 */
function removeLoadingAnimation() {
    const loadingEl = document.getElementById('cache-loading-animation');
    if (loadingEl) {
        loadingEl.remove();
    }
}

/**
 * Helper function to determine if all pages are cached.
 * @returns {boolean}
 */
async function hasPageCache(pageKey = null) {
    const cache = await caches.open(cacheNames.precache);
  
    if (pageKey) {
      const cachedHtml = await cache.match(pageKey);
      return !!cachedHtml;
    }
  
    // Check all pages if no specific key is provided.
    for (const page of pagesToCache) {
      const cachedHtml = await cache.match(page.key);
      if (!cachedHtml) return false;
    }
  
    return true;
  }
  

export { preloadRemotePages, loadCachedPage, hasPageCache, pagesToCache };
